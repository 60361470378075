import { OpenAPIRoute } from "@cloudflare/itty-router-openapi";
import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import {
    Routes,
    Route,
    Link
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from '../store';
import { Env } from "..";
import Home from "@ui/pages/home";
import Admin from "@ui/pages/admin";
import PageProperty from "@ui/pages/property";
import EditOrCreate from "@ui/pages/admin/routes/properties";

export const UIAndRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={
                <Home />
            } />
            <Route path="/property/:id" element={
                <PageProperty/>
            } />
            <Route path="/admin" element={
                <Admin/>
            } />
            <Route path="/admin/properties" element={<EditOrCreate/>}></Route>
            <Route path="/admin/properties/:slug" element={<EditOrCreate/>}></Route>
        </Routes>
    )
}

export class ReactSSR extends OpenAPIRoute {
    static schema = {};
    async handle(request: Request, env: Env, ctx: ExecutionContext) {
        const controller = new AbortController();

        const preloadedState = store.getState();

        const App = () => {
            return (
                <React.StrictMode>
                    <Provider store={store}>
                        <html>
                            <head>
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
                                <style>
                                    {`
                                    html, body, #root {
                                        height: 100%;
                                        width: 100%;
                                        margin: 0px;
                                    }
                                    `}
                                </style>
                                <link rel="stylesheet" href="/scripts/index.client.css" />
                            </head>
                            <body>
                                <div id="root">
                                    {/*
                                        <StaticRouter location={new URL(request.url).pathname}>
                                            <LayoutMain>
                                                <UIAndRoutes />
                                            </LayoutMain>
                                        </StaticRouter>
                                    */}
                                </div>
                                <script dangerouslySetInnerHTML={{
								__html: `
									window.__PRELOADED_STATE__ = ${JSON.stringify(preloadedState, null, 2).replace(
										/</g,
										'\\u003c'
									)}
								`}}>
							    </script>
                                <script type="module" src="/scripts/index.client.js" />
                            </body>
                        </html>
                    </Provider>
                </React.StrictMode>
            )
        }
        const stream = await ReactDOMServer.renderToReadableStream(
            <App/>,
            {
              signal: controller.signal,
              onError(error) {
                console.error(error);
              }
            }
        );
      
        return new Response(stream, {
            status: /*Check404.includes("<meta name=\"404\"\/>") ? 404 : */200, 
            headers: { 'Content-Type': 'text/html', 'Content-Security-Policy': 'frame-ancestors docs.google.com', 'X-Frame-Options': 'ALLOW-FROM docs.google.com' },
        });
    }
}