{
    "contact": {
        "email": [
            "pvone2023@gmail.com",
            "kinacastaneda@gmail.com"
        ],
        "phone": "+52322-229-9247",
        "phoneLabel": "(322) 229-9247",
        "phoneOffice": "+52322-137-7647",
        "phoneOfficeLabel": "(322) 137-7647",
        "facebook": "https://www.facebook.com/p/Administraci%C3%B3n-Venta-y-Renta-PV-100065371723705/",
        "instagram": "https://www.instagram.com/pvrone_/"
    }
}